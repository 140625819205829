.container-search {
  width: 100%;
  height: 48px;
  margin: auto 0;

  @media (min-width: 992px) {
    width: calc(95vw - 750px);
  }

  .ant-select-dropdown-placement-bottomLeft {
    position: fixed;
  }
  .ant-select-item {
    margin: 8px 0;
  }
  .ant-select-selector {
    border-right-width: 0 !important;
  }

  .bg-light {
    background-color: #fff !important;
    input {
      background-color: #fff !important;
    }

    .icon-right {
      background-color: #fff !important;
      border-width: 0.8px 0.8px 2.4px 0.8px !important;
      color: #8D8A95 !important;
      border-style: solid !important;
      border-color: #8D8A95 !important;
      
    }
  }
  .input-search {
    display: flex;
    width: calc(95vw - 750px);
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    margin: auto;
    // height: 40px;
    border-radius: 8px;
    background-color: #f3f3f4;
    // border: 1px solid var(--grayscale-light-90, #e6e6e6);
    border: 0px;

    @media (max-width: 1200px) {
      width: 324px;
      min-width: 0;
    }

    @media (max-width: 1024px) {
      width: 224px;
      min-width: 0;
      padding: 8px;
    }

    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper-focused {
      border-color: #e6e6e6;
      box-shadow: none;
      border-right-width: 1px;
      outline: 0;
    }

    .ant-input {
      background-color: #f3f3f4;
      color: #8d8a95;

      ::placeholder {
        color: #8d8a95;
      }
    }

    &.ant-input:focus,
    &.ant-input-focused {
      border-color: #e6e6e6;
      box-shadow: none;
      border-right-width: 1px;
      outline: 0;
    }
    &.ant-input-affix-wrapper:hover {
      border-color: #e6e6e6;
      border-right-width: 1px;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }

    .anticon-search {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #8d8a95;
      cursor: pointer;

      :hover {
        opacity: 0.7;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .icon-right {
      display: flex;
      width: 22px;
      height: 22px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 6px;
      background: var(--grayscale-light-90, #e6e6e6);
      border-top: 0.8px solid var(--Grey-500, #8d8a95);
      border-right: 0.8px solid var(--Grey-500, #8d8a95);
      border-bottom: 2.4px solid var(--Grey-500, #8d8a95);
      border-left: 0.8px solid var(--Grey-500, #8d8a95);
    }
  }

  .certain-category-search-dropdown {
    .ant-select-dropdown-menu-item-group-title {
      color: #666;
      font-weight: bold;
    }

    .ant-select-dropdown-menu-item-group {
      border-bottom: 1px solid #f6f6f6;
    }

    .ant-select-dropdown-menu-item {
      padding-left: 16px;
    }

    .ant-select-dropdown-menu-item.show-all {
      text-align: center;
      cursor: default;
    }

    .ant-select-dropdown-menu {
      max-height: 300px;
    }
  }

  .title-search {
    padding: 0 8px 8px 8px;
    span {
      font-size: 12px;
      line-height: 16px;
      color: #e6e6e6;
      font-weight: bold;
      padding: 12px;
      cursor: pointer;

      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 24px;
        padding: 16px;
      }
    }
    .active {
      border-bottom: 2px solid #050505;
      color: #050505;
    }
  }

  .item-search {
    p {
      margin: 0;
      font-weight: bold;
    }
  }
  .footer-search {
    font-size: 12px;
    font-weight: bold;
    color: #666666;

    @media (min-width: 992px) {
      font-size: 14px;
    }
  }

  .video-container {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 3px;
    margin-right: 16px;

    iframe {
      box-sizing: border-box;
      height: 56.25vw;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: fit-content;
    }
  }
}

.certain-category-search-dropdown {
  input {
    font-size: 12px;
    line-height: 16px;

    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .ant-select-dropdown {
    .ant-select-item-option-grouped {
      padding-left: 16px;

      @media (min-width: 992px) {
        padding-left: 24px;
      }
    }

    .collectionCell-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0;
      overflow: hidden;
      &:hover {
        img {
          transition: 0.3s;
          transform: scale(1.1);
        }
      }
      .collectionCell-avatar.ant-avatar {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        color: #ffffff;
        margin-right: 8px;

        @media (min-width: 992px) {
          margin-right: 16px;
        }
      }
      .collectionCell-avatar.ant-avatar-square {
        border-radius: 4px;
      }
      .collectionCell-title {
        flex-shrink: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.search-dropdown {
    border-radius: 12px;
}
