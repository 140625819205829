.vertical-layout-wrapper {
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 1440px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.vertical-layout-full-wrapper {
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}
