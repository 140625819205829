.sign-wallet-wrapper {
  width: 526px;

  @media (max-width: 767px) {
    position: absolute;
    bottom: 0;
  }

  &.ant-modal {
    @media (max-width: 767px) {
      max-width: calc(100vw - 0px);
      margin: 0;
      top: initial;
      padding-bottom: 0;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    @media (max-width: 767px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    text-align: center;
    img {
      margin: auto;
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding: 0px 32px 32px 32px;
    display: flex;
    flex-direction: row;
    border-top: none;
  }
  .sign-wallet-title {
    color: #2750fb;
    text-align: center;
    font-family: "Arabic Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .sign-wallet-content {
    color: #050505;
    font-family: "Arabic Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .sign-wallet-note {
    display: flex;
    flex-direction: row;
    padding: 12px 16px 12px 16px;
    background-color: #f0f0f0;
    align-items: center;
    gap: 8px;

    .anticon.anticon-info-circle {
      color: #00c70a;
      font-size: 24px;
    }
    .sign-wallet-note-content {
      color: var(--grayscale-light-black, #050505);
      font-family: "Arabic Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .sign-wallet-btn {
    height: 56px;
    padding: 16px 24px;
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--grayscale-light-90, #e6e6e6);
    color: var(--grayscale-light-black, #050505);

    span {
      /* Desk/L - Title */
      font-family: "Arabic Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .btn-active {
    background-color: #2750fb;
    color: #e6e6e6;
  }
}
