.table-custom {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-table-cell {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
    @media (max-width: 768px) {
      max-width: 138px;
    }
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: none;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: none;
  }

  .icon-favorite {
    .anticon-star {
      font-size: 24px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;

        @media (max-width: 768px) {
          width: 16px;
          height: 16px;
        }

        &:hover {
          color: #666666;
        }
      }
    }
  }
  .percent-positive {
    color: #00c70a;
  }
  .negative-percent {
    color: #fc4b23;
  }

  .collection-cell {
    min-width: 406px;
    @media (max-width: 768px) {
      min-width: 138px;
    }
  }
  .action-cell {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .action-cell-mobile {
    display: none;
    @media (max-width: 768px) {
      display: table-cell;
    }
  }
}
