.text-design {
  background: #f7f7f7;
  padding: 16px;
  text-align: center;
  h2 {
    text-transform: uppercase;
    color: #25b8e9;
    font-weight: 600;
    font-size: 39px;
    margin-bottom: 0;
    @media (max-width: 476px) {
      font-size: 25px;
    }
    @media (max-width: 414px) {
      font-size: 20px;
    }
  }
}
