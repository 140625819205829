/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: ""Arabic Pro"", sans-serif;
}
:root {
  --white-color: #fff;
  --blue-color: #4070f4;
  --grey-color: #707070;
  --grey-color-light: #aaa;
}
body {
  background-color: #e7f2fd;
  // transition: all 0.5s ease;
}
body.dark {
  background-color: #333;
}
body.dark {
  --white-color: #333;
  --blue-color: #fff;
  --grey-color: #f2f2f2;
  --grey-color-light: #aaa;
}

/* navbar */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  z-index: 1000;
  box-shadow: 0 0 1px var(--grey-color-light);
  left: 187px;
  transition: all 0.5s ease;
  width: "calc(100% - 187px)";
  z-index: 999;
}

.body_content {
  width: 100%;
  display: flex;
  // text-align: center;
  align-items: center;
  position: relative;
  transition: all 0.5s ease;
  width: "calc(100% - 187px)";
  /* background: var(--grayscale-light-white, #fafafa); */
}

.layout-container {
  width: 100%;
}

.logo_item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 22px;
  font-weight: 500;
  color: var(--blue-color);
}
.navbar img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.search_bar {
  height: 47px;
  max-width: 430px;
  width: 100%;
}
.search_bar input {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  font-size: 18px;
  outline: none;
  background-color: var(--white-color);
  color: var(--grey-color);
  border: 1px solid var(--grey-color-light);
  padding: 0 20px;
}
.navbar_content {
  display: flex;
  align-items: center;
  column-gap: 25px;
}
.navbar_content i {
  cursor: pointer;
  font-size: 20px;
  color: var(--grey-color);
}

/* sidebar */
.sidebar {
  display: none;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 16px 16px;
  z-index: 999;
  overflow-y: scroll;
  box-shadow: 0 0 1px var(--grey-color-light);
  transition: all 0.3s ease;
  @media (max-width: 991px) {
    padding: 16px;
    width: 306px;
    display: block;
  }
  @media (max-width: 650px) {
    width: 100vw;
  }
}
.logo-detail {
  display: flex;
  height: 60px;
  width: 100%;
  font-size: 22px;
  color: #666666;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.sidebar.close {
  width: 80px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.menu_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 100px);
  margin-top: 30px;
  @media screen and (max-width: 991px) {
    height: auto;
  }
}

.sidebar_footer {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    padding-top: 15px;
    margin-top: 15px;
    // margin-bottom: 95px;
    gap: 15px;
  }
}
.menu_title {
  margin: 15px 0;
  padding: 0 20px;
  font-size: 18px;
}
.sidebar.close .menu_title {
  padding: 6px 30px;
}
.menu_title::before {
  color: var(--grey-color);
  white-space: nowrap;
}
.menu_dahsboard::before {
  content: "Dashboard";
}
.menu_editor::before {
  content: "Editor";
}
.menu_setting::before {
  content: "Setting";
}
.sidebar.close .menu_title::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 18px;
  border-radius: 12px;
  background: var(--grey-color-light);
}
.menu_items {
  padding: 0;
  list-style: none;
}
.navlink_icon {
  position: relative;
  font-size: 0px;
  display: inline-block;
  text-align: center;
  border-radius: 6px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.sidebar.close {
  .navlink {
    display: none;
  }
  .arrow-left {
    display: none;
  }
  .sub-menu {
    display: none;
  }
}
.nav_link {
  & > div.flex {
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    text-decoration: none;
    color: var(--grey-color);
    white-space: nowrap;

    svg path {
      fill: #666666;
    }
  }

  &:hover {
    & > div.flex {
      background: #f0f0f0;

      & .navlink {
        color: #050505;
      }

      svg path {
        fill: #050505;
      }
    }
  }

  &.active {
    & > div.flex {
      background: #1da7df1a;

      & .navlink {
        color: #2750FB;
      }

      svg path {
        fill: #2750FB;
      }
    }
  }
}

.item_active {
  color: var(--white-color);
  background: rgba(29, 167, 223, 0.1);
}
.sidebar.close .nav_link:hover {
  background: rgba(29, 167, 223, 0.1);
}
// .submenu_item {
//   cursor: pointer;
// }
.submenu {
  display: none;
}
.nav_link .arrow-left {
  position: absolute;
  left: 120px;
  display: inline-block;
  margin-right: auto;
}
.sidebar.close .submenu {
  display: none;
}
.show_submenu ~ .submenu {
  display: block;
}
.show_submenu .arrow-left {
  transform: rotate(90deg);
}
.submenu .sublink {
  padding: 15px 15px 15px 52px;
}
.bottom_content {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 187px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.bottom {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: space-around;
  padding: 18px 0;
  text-align: center;
  width: 100%;
  color: var(--grey-color);
  border-top: 1px solid var(--grey-color-light);
  background-color: var(--white-color);
}
.bottom i {
  font-size: 20px;
}
.bottom span {
  font-size: 18px;
}
.sidebar.close .bottom_content {
  width: 50px;
  left: 15px;
}
.sidebar.close .bottom span {
  display: none;
}
.sidebar.hoverable .collapse_sidebar {
  display: none;
}
#sidebarOpen {
  display: none;
}
@media screen and (max-width: 991px) {
  #sidebarOpen {
    font-size: 25px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    color: var(--grey-color);
  }
  .sidebar.close {
    left: -100%;
  }
  .search_bar {
    display: none;
  }
  .sidebar.close .bottom_content {
    left: -100%;
  }
}

.overlay.close {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(17, 17, 17, 0.3607843137254902);
  transition: 0.4s;
}

.overlay.open {
  display: block;
}
