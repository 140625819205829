%bg-launchpad {
  background-image: linear-gradient(120deg, #f6feff, #eff6fe) !important;
}

.launchpad-header-wrapper {
  @extend %bg-launchpad;
  border-bottom: 1px solid #e2e2e4;

  .launchpad-header-container {
    display: flex;
    padding: 24px;
    gap: 8px;
    align-items: baseline;
    flex-wrap: wrap;
    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      @media only screen and (max-width: 519px) {
        font-size: 35px;
      }
    }
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}

.header-wrapper {
  display: flex;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
  gap: 16px;
  // flex-wrap: wrap;

  @media (max-width: 991px) {
    padding: 16px;
  }

  @media (max-width: 370px) {
    gap: 12px;
  }

  &-launchpad {
    @extend %bg-launchpad;
  }

  .header-left-wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 68%;
    .group-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 991px) {
        display: none;
      }
    }
    .anticon-logo {
      display: block;
      align-self: center;
      display: flex;
      width: 167px;
      height: 40px;
      align-items: flex-start;
      object-fit: cover;
      cursor: pointer;

      @media (max-width: 991px) {
        display: block;
        align-self: center;
        display: flex;
        width: 165px;
        height: 40px;
        padding: 0 !important;
        align-items: flex-start;
        gap: 10px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
  .header-right-wrapper {
    .btn-create {
      display: flex;
      align-items: center;
      color: var(--brand-color-main-blue, #2750fb);
      /* Desk/L - Title */
      font-family: "Arabic Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  .no-connect-wallet-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      gap: 8px;
    }
  }
  .connect-wallet-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      gap: 8px;
    }
  }
  .anticon-search {
    align-self: center;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: flex-start;
    gap: 10px;
    font-size: 36px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 370px) {
      width: 24px;
      height: 24px;
    }
  }
  .anticon-shopping {
    font-size: 24px;
  }
  .anticon-bell,
  .anticon-user,
  .anticon-menu {
    align-self: center;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    font-size: 32px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 370px) {
      width: 28px;
      height: 28px;
    }
  }
}

.btn-menu {
  color: var(--grayscale-light-black, #050505);
  /* Desk/L - Title */
  font-family: "Arabic Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:hover,
  &:focus {
    border: 1px solid transparent !important;
  }
}

.header-dropdown {
  width: 130px;
  background: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 5px;
  .ant-dropdown-menu {
    box-shadow: none;
    padding: 0;
    li {
      border-radius: 10px;
    }
  }
}
