.profile {

  :global(.ant-drawer-mask) {
    background: rgba(0, 0, 0, 0.45) !important;
  }

  :global(.ant-drawer-content-wrapper) {
    width: 519px !important;
    box-shadow: none;
    padding: 30px;

    @media only screen and (max-width: 519px) {
      width: 100vw !important;
      padding: 0;
      :global(.ant-drawer-body) {
        border-radius: 0 !important;
      }
      :global(.ant-drawer-content) {
        height: 100% !important;
      }
    }

    :global(.ant-drawer-content) {
      height: unset;
    }

    :global(.ant-drawer-header) {
      display: none;
    }

    :global(.ant-drawer-body) {
      background: #FFFFFF !important;
      border-radius: 10px;
      position: relative;
    }
  }

  .exit {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    color: #8D8A95;
    &:hover {
      color: #2750FB;
    }
  }

  .navi {
    padding: 24px 12px;
    border-radius: 10px;
    border: 1px solid #E2E2E4;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .username {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #2E293D;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    a {
      width: fit-content;
    }

    .navi_btn {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0;
      border: none;
      height: auto;

      svg,
      img {
        width: 20px;
        height: 20px;

        path {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #2E293D;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover {
        svg {
          path {
            stroke: #2750FB;
          }
        }

        .eye {
          path {
            stroke: unset;
            fill: #2750FB;
          }
        }

        span {
          color: #2750FB;
        }
      }
    }
  }

  .wallet {
    margin: 20px 0;

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #8D8A95;
      }
    }

    .content {
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #E2E2E4;
      display: flex;
      flex-direction: column;
      gap: 11px;

      .header {
        display: flex;
        gap: 24px;
        align-items: center;
        padding: 0 12px;

        .name {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          p {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: #2E293D;
          }

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #2E293D;
          }
        }

        button {
          border: none;
          color: #2E293D;

          &:hover {
            color: red;
          }
        }
      }

      .balance {
        padding: 10px 16px;
        gap: 12px;
        border-radius: 8px;
        background: #F3F3F4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        span {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          color: #8D8A95;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          color: #2E293D;
        }
      }
    }
  }

  .fund {
    padding: 24px 12px;
    gap: 24px;
    border-radius: 10px;
    background: #F3F3F4;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
    }

    button {
      width: 100%;
      height: 42px;
      border-radius: 8px;
      font-weight: 700;
      padding: 0;
    }

    .swap_btn {
      border-color: transparent;
      color: #2E293D;
      background: #FFFFFF;

      &:hover {
        color: #2750fb;
      }
    }

    .add_btn {
      border-color: #2750fb;
      color: #ffffff;
      background: #2750fb;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}