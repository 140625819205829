@media (min-width: 1200px) {
  .ant-col-custom-xl-7 {
    display: block;
    flex: 0 0 14.2%;
    max-width: 14.2%;
  }

}
@media (min-width: 768px) {
  .ant-col-custom-md-5 {
    display: block;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
