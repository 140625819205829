.modal-success {
  @media (max-width: 991px) {
    position: fixed;
    top: auto;
    bottom: 0;
    padding-bottom: 0;
    min-width: 100%;
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 16px 16px 0 0;
    background: #fefefe;

    @media (min-width: 992px) {
      border-radius: 16px;
    }
  }

  .ant-modal-close {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #050505;
      }
    }
  }

  .ant-modal-header {
    border-radius: 16px;
    border-bottom: 0;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-bottom: 12px;
    }

    .ant-modal-title {
      color: #050505;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;

      @media (min-width: 991px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .ant-modal-body {
    padding: 24px 16px;

    @media (min-width: 991px) {
      padding: 32px;
    }

    .approve-nft-action {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;

      button {
        color: #fafafa;
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: 700;
        border-radius: 8px;
        background: #2750fb;
        border: 1px solid #2750fb;
        padding: 12px 16px;
        width: 100%;

        &:first-child {
          background: transparent;
          border: 1px solid #e6e6e6;
          color: #050505;

          &:hover {
            border: 1px solid #050505;
          }
        }

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
          padding: 16px 24px;
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 12px 16px;
  }
}
