.card-wrapper {
  position: fixed;
  font-size: 16px;
  bottom: 3em;
  top: 1.5em;
  z-index: 999;
  height: calc(((100% - 0px) - 48px));

  overflow: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;

  width: 0px;
  border-radius: 1em;

  border-bottom: 0px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    bottom: 0;
    top: 162px;
    height: calc(((562px - 0px) - 48px));
  }

  &.open {
    right: 1.5em;
    width: 28.75em;
    @media (max-width: 768px) {
      width: 100%;
      right: 0;
    }
  }
  &.close {
    right: -100%;
  }
  .ant-dropdown-menu {
    box-shadow: none;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 1.5em;
  }
  .ant-card-extra {
    padding: 0;
  }
  .ant-card-head {
    margin-top: 12px;
    min-height: 3em;
    margin-bottom: 0px;
    padding-left: 1.5em;
    padding-top: 0.75em;
    padding-bottom: 0px;
    padding-right: 1.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 1em;
    background: transparent;
    border-bottom: 0px solid #f0f0f0;
    border-radius: 0px 2px 0 0;
  }
  .card-title {
    .ant-card-body {
      display: none;
    }

    .ant-card-bordered {
      border: 0px solid #f0f0f0;
    }

    .ant-card-head {
      border-bottom: 0px solid #f0f0f0;
      .ant-card-head-title {
        color: var(--grayscale-light-black, #050505);
        /* Desk/H2 - Title */
        font-family: "Arabic Pro";
        font-size: 1.5em;
        font-style: normal;
        font-weight: 700;
        line-height: 2em;
        padding: 0;
      }
      .anticon-close {
        cursor: pointer;
        font-size: 1.5em;
        :hover {
          opacity: 0.7;
        }
      }
    }
  }
  .cart-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.875em;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    .list-item {
      margin-top: 12px;
    }
    .cart-title {
      display: flex;
      justify-content: space-between;
      color: var(--grayscale-light-black, #050505);
      /* Desk/L - Title */
      font-family: "Arabic Pro";
      font-size: 1em;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5em;
    }
    .cart-container {
      max-height: calc(625px - 200px);
      overflow-x: auto;
      position: relative;
      transition: opacity 0.3s;
      margin-bottom: 1.5em;
      @media (max-width: 768px) {
        max-height: calc(400px - 162px);
      }
    }
  }

  .wrap-item-cart-checkbox {
    .ant-checkbox-wrapper {
      width: 100%;

      span {
        &.ant-checkbox {
          width: 16px;
        }

        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
