.cart-footer-wrapper {
  font-size: 0.875em;
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  min-width: 90%;
  .cart-footer-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
