@import "../../../assets/css/variables.scss";

$p-text: #777e91;

.ant-layout.layout-container {
  background-color: #fff;
  background-repeat: repeat;
  background-size: cover;
  z-index: 9999;

  .ant-layout-header {
    box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
    max-width: 100vw;
    height: 72px;
    position: sticky;
    top: 0px;
    transition: top 0.5s ease 0s;
    background-color: rgb(255, 255, 255);
    padding: 0;
  }

  .border-bottom-menu {
    position: sticky !important;
    z-index: 999;
    top: 0px;
    background: #fff;
    box-shadow: 0px 1px 10px #00000012;
  }
}

.box-container-menu {
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;

  @media (min-width: 1440px) {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 0 !important;
  }

  @media (max-width: 1440px) {
    // width: 90%;
    // margin: 0 auto;
    width: initial;
    max-width: initial;
    padding: 0 34px !important;
  }
}

.container-mobile {
  width: 100%;

  .col-right {
    margin-left: auto;
  }
}

.profile-popup-mobile {
  padding: 25px;

  @media (max-width: 768px) {
    padding: 0;

    .separate {
      display: none;
    }

    .img-sub {
      display: none;
    }

    .profile-popup-container {
      .button {
        padding: 16px 36px;
        margin-left: 0 !important;

        p {
          color: #333;
          font-size: 16px;
        }
      }
    }
  }
}

.header-container {
  box-shadow: none !important;
  line-height: 40px !important;
  background: linear-gradient(
    180deg,
    rgba(224, 230, 237, 1) 32%,
    rgba(249, 250, 252, 1) 77%
  );
  width: 100%;
  display: flex;
  height: 100% !important;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .profile-user {
    display: flex;
    position: relative;
    cursor: pointer;

    .icon-tick {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .wallet-address {
    display: flex;
    background: linear-gradient(139deg, #3cbadd 32%, #48c3c4 77%);
    border-radius: 16px;
    padding: 0 10px;
    margin-left: 10px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .show-balance {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
    min-width: 50px;
  }

  .header-absolute {
    display: flex;
    position: absolute;
    top: 79px;
    right: 0;

    .btn-header {
      margin: 0 8px;

      img {
        height: 100%;
      }
    }
  }

  .logo-left {
    padding-left: 0;

    img {
      margin-top: 25px;
    }
  }

  .search-nav {
    width: 100%;
    display: none;
    justify-content: space-between;
    max-width: 574px;
    align-items: center;

    .wrapper-search {
      max-width: 256px;
      background-color: transparent;
      // font-family: SVN Avo;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #777e91;
      border: 2px solid #353945;

      input {
        background-color: transparent;
      }
    }

    button,
    button.button-wrapper-primary span {
      // font-family: SVN Avo;
      font-style: normal;
      font-weight: bold;
      font-size: 14px !important;
      line-height: 16px;
      color: #fcfcfd;
    }

    @media (min-width: 991px) {
      display: flex;
    }
  }

  .search-container {
    width: 40%;
    height: 36px;
    border: 1px solid #c5c6dd;
    border-radius: 40px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(196, 197, 220, 0.25);
    margin-right: 16px;

    .icon {
      margin-right: 22px;
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      height: 100%;
    }

    ::placeholder {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #c5c6dd !important;
    }
  }

  .av-login {
    border-radius: 50%;
  }

  .btn-group {
    display: flex;
    align-items: center;
  }

  .wrap-btn {
    padding: 10px 8px;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
  }

  .wrap-av {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-right {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .menu-item {
      text-decoration: none;
      height: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #272727;
      padding-right: 40px;
      display: flex;
      align-items: center;
      position: relative;

      .icon-arrow {
        margin-right: 6px;
      }

      .line {
        margin-left: 6px;
      }

      span {
        width: 1px;
        background: #fafafb;
        height: 18px;
        box-shadow: 2px 2px #888888;
      }
    }

    .menu-item:last-child {
      padding-right: 0 !important;
    }

    .bugger-collapse {
      line-height: 50px;
      padding-right: 0;

      svg {
        path {
          fill: #777e90;
        }
      }
    }

    @media (min-width: 991px) {
      justify-content: flex-start;
      align-items: center;
    }

    .ant-menu {
      min-width: 375px;
      background: transparent;
      border-color: transparent;
      width: 100%;
      display: flex;
      text-decoration: none !important;
    }

    .ant-menu-horizontal .ant-menu-submenu {
      transition: unset;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark)
      > .ant-menu-submenu-selected::after {
      border-bottom: 2px solid transparent;
    }

    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
      background-color: transparent;
    }

    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
      padding: 0 16px;

      &:first-child {
        margin-left: 16px;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item a {
      color: $p-text;
      height: 100%;
      // font-family: SVN Avo;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      img {
        width: 28px;
        border-radius: 50%;
      }
    }

    @media (min-width: 991px) {
      gap: 0;
    }
  }

  .wallet-icon {
    color: $p-text;
    transition: color 1s ease 0s, background-color 1s ease 0s;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0px 20px;
    cursor: pointer;
  }

  .bugger-collapse {
    padding-right: 20px;
    line-height: 86px;

    svg {
      font-size: 26px;
    }
  }

  .have-noti {
    position: relative;

    img {
      padding: 6px;
    }

    &::before {
      content: " ";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #45b26b;
      top: 3px;
      right: -6px;
    }
  }
}

// Dropdown submenu style
// .ant-menu-submenu-popup {
//   position: fixed !important;
//   top: 69px !important;
// }

// .ant-menu.ant-menu-sub {
//   .ant-menu {
//     border-radius: 6px;
//   }

//   .ant-menu-item {
//     color: $p-text;
//     font-size: 16px;
//     font-weight: 600;
//     height: 100%;
//     padding: 5px 45px 5px 16px;
//     margin-bottom: 0;
//     margin-top: 0;

//     border-bottom: 1px solid rgb(229, 232, 235);

//     img {
//       margin-right: 8px;
//     }

//     &:hover {
//       transition: all 0.2s ease 0s;
//       box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
//       background-color: rgb(251, 253, 255);

//       a {
//         color: $p-text;
//       }
//     }
//   }
// }

// Draw menu on mobile style
.ant-drawer-open.ant-drawer.menu-drawer-mobile {
  .ant-drawer-close {
    svg {
      height: 24px;
    }
  }
}

.ant-drawer.menu-drawer-mobile {
  z-index: 99990;

  .btn-draw {
    font-size: 22px !important;
    width: 75%;
    margin-left: auto !important;
    margin-right: auto;
    padding: 10px 10px;
    text-align: center;
    box-shadow: 0px 0px 16px white;
  }

  .ant-drawer-close {
    display: none;
    font-size: 24px;
    background: none;
    top: 20px;
    right: 26.6px;
    padding: 6px;
    margin-left: auto;

    svg {
      transition: 1s;
      height: 0px;

      path {
        fill: #777e90;
      }
    }
  }

  .search-nav {
    padding-top: 4px;

    .wrapper-search {
      height: 60px;
      border: unset;
      border-bottom: 1px solid rgb(229, 232, 235);
    }
  }

  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    white-space: nowrap;
    background: #ffffff;

    .ant-menu-root,
    .ant-menu-inline {
      border-right: none;
      height: calc(100% - 225px);
      overflow-x: hidden;
      overflow-y: auto;

      .ant-menu-submenu-title {
        padding: 0 16px !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #272727;

        .ant-menu-submenu-arrow {
          color: #272727;
        }
      }

      .ant-menu-submenu {
        .ant-menu-sub {
          background-color: #ffffff;

          .ant-menu-title-content {
            font-weight: 600;
            font-size: 14px;
            color: #272727;
            line-height: 22px;
          }
        }
      }

      .ant-menu-item {
        .ant-menu-title-content {
          font-weight: 600;
          font-size: 14px;
          color: #272727;
          line-height: 22px;
        }

        .ant-menu-item-icon {
          margin-left: -6px;
        }
      }
    }

    .drawer-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #ffffff;
      padding: 0 16px;

      .button-create-nfts {
        margin-top: 32px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          padding: 16px 32px;
          background: #25b8e9;
          border-radius: 100px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      .social-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        border-top: 1px solid #e6e6e6;
        padding: 32px 0;
      }
    }
  }

  // .ant-menu {
  //   flex: 1 1 0%;
  //   border-right: unset;

  //   .ant-menu-submenu-title,
  //   .ant-menu-item {
  //     color: $p-text;
  //     font-size: 16px;
  //     font-weight: 600;
  //     margin-bottom: 0;
  //     margin-top: 0;

  //     img {
  //       width: 32px;
  //       margin-right: 10px;
  //     }

  //     &:hover {
  //       transition: all 0.2s ease 0s;
  //       box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  //       background-color: rgb(251, 253, 255);

  //       a {
  //         color: $p-text;
  //       }
  //     }
  //   }

  //   .ant-menu-submenu-title {
  //     height: 100%;
  //     padding: 18px;
  //   }

  //   .ant-menu-item {
  //     padding: 18px 45px 57px 18px;
  //   }

  //   .ant-menu-item:active {
  //     background: none !important;
  //     box-shadow: none !important;
  //     color: #fff;
  //   }
  // }
}

.ant-drawer-content {
  background-color: transparent !important;

  .ant-drawer-wrapper-body {
    // padding: 0 16px;

    .ant-drawer-header {
      padding: 0;
      border-bottom: none;

      .ant-drawer-header-title {
        height: 72px;
      }
    }
  }

  // .ant-drawer-body {
  //   .ant-menu {
  //     .btn-connect {
  //       display: flex !important;
  //       justify-content: center !important;
  //     }

  //     .ant-menu-item {
  //       padding: 0;
  //       line-height: 1;
  //       height: auto;
  //       padding-left: 32px;
  //       margin-bottom: 32px;

  //       a {
  //         color: black;

  //         &.active {
  //           color: #ffffff;
  //         }
  //       }
  //     }

  //     .button-transparent {
  //       margin-left: 32px;
  //     }
  //   }
  // }
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  transition: unset !important;
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.customize-tooltip {
  background-color: #fff !important;
  opacity: 1 !important;
  border-radius: 16px !important;
  min-width: 220px;
  box-shadow: 0px 2px 3px 2px #52525280;
  padding: 0 4px !important;
  overflow: hidden;
  z-index: 10000 !important;
}

.customize-tooltip::after {
  display: none !important;
}

.customize-notification-tooltip {
  background-color: #141416 !important;
  opacity: 1 !important;
  border-radius: 16px !important;
  padding: 40px 16px 32px 16px !important;
  margin-top: 27px !important;
  width: 280px !important;

  @media (min-width: 991px) {
    width: 369px !important;
  }
}

// @media (max-width: 820px) {
// .ant-drawer-content .ant-drawer-body {
//   padding: 0px !important;
//   padding-top: 0px !important;
// }

// .ant-drawer-header {
//   padding: 24px 24px !important;
// }

// .ant-drawer-content .ant-drawer-body .ant-menu .ant-menu-item {
//   padding: 20px;
//   padding-left: 36px !important;
// }
// }

@media (max-width: 768px) {
  .box-container-menu {
    padding: 0 16px !important;

    .container-mobile {
      justify-content: space-between;
    }
  }

  // .ant-drawer-content .ant-drawer-body {
  //   padding: 0px !important;
  //   padding-top: 0px !important;
  // }

  // .ant-drawer-header {
  //   padding: 24px 24px !important;
  // }

  .header-container {
    .search-container {
      display: none;
    }

    .logo-left {
      width: 100%;

      a {
        width: 100%;
      }

      img {
        height: 60px;
      }
    }

    .col-right {
      align-items: center;

      .bugger-collapse {
        display: flex;
        align-items: center;
      }

      .have-noti::before {
        width: 8px;
        height: 8px;
        top: 8px;
        right: 1px;
      }

      .have-noti {
        img {
          width: 36px;
        }
      }
    }
  }

  .footer-container {
    padding-top: 0px;

    .ant-col {
      padding-top: 16px;
    }

    .list-footer {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
      }
    }
  }
}

.right-header-mobile {
  align-items: center;

  .profile-user {
    margin-right: 16px;
  }

  .wrap-btn {
    margin-right: 16px;
  }

  .wallet-address {
    margin-right: 16px;
    width: 80px;
    padding: 8px 5px;
    height: fit-content;
    line-height: 24px;
  }
}

// .ant-drawer-content .ant-drawer-body .ant-menu .ant-menu-item a {
//   display: flex;
//   align-items: center;
//   color: #ffffff;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 22px;
// }

// .ant-drawer-content .ant-drawer-body .ant-menu {
//   background: linear-gradient(#4eaade 0%, #69bab5 100%);
// }

// .ant-drawer-content .ant-drawer-body .ant-menu .ant-menu-item {
//   margin-bottom: 0px !important;
//   padding-left: 24px;
// }

.ant-drawer.menu-drawer-mobile .ant-drawer-close {
  border-radius: 4px;
  background-color: none;
}

.logo-left-drawer {
  top: 16px;
  left: 16px;
  position: absolute;
  // width: 134px;

  img {
    //width: 252px;
    height: 58px;
  }
}

.drawer-close {
  position: absolute;
  top: 23px;
  right: 16px;
}

.logout-item {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
