@import "./mixins/_class.scss";
@import "./mixins/_mixins.scss";
@import "./mixins/_breakpoints.scss";

$base-font: "Poppins";

$primary-color: #141416;

$aside-menu-background: #003366;
$aside-menu-color: #038fdd;

$text-primary-color: #038fde;
$success-color: #14d1a4;

$header-background-color: #cfe8ff;

$header-menu-color: #f9354c;

$box-shadow-200: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;

/* ======> Modal <======*/
$modal-body-text: #a25759;
