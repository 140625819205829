.information-payment-wrapper {
  .pd-1 {
    padding: 0em 1em;
  }
  .anticon-up {
    cursor: pointer;
    color: #050505;
    display: flex;
    align-items: center;
    :hover {
      opacity: 0.7;
    }
  }
  .cart-content-payment {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--grayscale-light-90, #e6e6e6);
    align-self: stretch;
    transition: all 0.5s ease;

    .content-payment-total {
      padding: 1em 0;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--grayscale-light-90, #e6e6e6);

      .payment-total {
        flex: 1;
        display: flex;
        gap: 0.25em;
      }
    }
    .cart-content-checkout {
      padding: 1em 1em;
      display: flex;
      justify-content: space-between;
      border-radius: 0.5em;
      background: var(--grayscale-light-94, #f0f0f0);
    }

    .content-payment-fee {
      padding: 1em 0;

      display: flex;
      flex-direction: column;
      gap: 1em;

      .payment-fee-stack {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
