body {
  margin: 0;
  font-family: "Arabic Pro";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body > iframe {
  display: none;
}

@media (min-width: 768px) {
  .tablet-show {
    display: block !important;
  }
  .mobile-show {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .tablet-show {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
}

.w-75 {
  width: 75%;
}

.div-center {
  margin-left: auto;
  margin-right: auto;
}

.text-bold {
  font-weight: bold;
}

.ant-drawer-header {
  background: #ffffff;
}
