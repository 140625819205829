@import "./variables.scss";
@import "./font-face.scss";
@import "./create-class.scss";
@import "./share-class.scss";

* {
  margin: 0;
  padding: 0;
}

*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: auto;
}

#root {
  width: 100%;
  height: 100%;
  color: rgb(53, 56, 64);
  --font-default: "Arabic Pro";
  --desk-size: 16px;
  --mobile-size: 16px;
  --desk-l-size: 12px;

  --desk-h1-size: 40px;

  --grayscale-light-black: #050505;

  --gap-base: 16px;
  --gap-5: 5px;
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-24: 24px;
  --gap-40: 40px;

  --br-5xs: 8px;
  --br-9: 9px;
  --br-base: 16px;
  --br-8: 8px;
  --br-4: 4px;

  /* Colors */
  --grayscale-light-white: #fafafa;
  --grayscale-light-black: #050505;
  --grayscale-light-black-50: rgba(5, 5, 5, 0.5);
  --grayscale-light-90: #e6e6e6;
  --grayscale-light-60: #999;
  --grayscale-light-secondary: #666;
  --brand-color-main-blue: #2750fb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 110%;
}

h2 {
  font-weight: 600;
  font-size: 39px;
  line-height: 49px;
  color: #272727;
  margin-bottom: 0;
  // @media (max-width: 767px) {
  //   line-height: 50px !important;
  // }
}

hr.hr-1 {
  border: 0.1px solid rgba(227, 228, 230, 0.1);
}

button {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-switch {
  background-color: #fff;
}

.ant-switch-checked {
  background-color: #25b8e9;

  .ant-switch-handle::before {
    background-color: #fff;
  }
}

.ant-switch-handle::before {
  background-color: #959595;
}

.react-toast-notifications__container {
  z-index: 999999 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 99px;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #59c6f1;
// }

.explore-nft-select {
  padding: 16px;
  border-radius: 8px;
  min-width: fit-content !important;

  @media (max-width: 767px) {
    padding: 8px;
  }

  .ant-select-item {
    padding: 8px 16px;

    @media (max-width: 767px) {
      padding: 12px 8px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .ant-select-item-option-content {
      color: #050505;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      @media (max-width: 767px) {
        font-size: 12px !important;
        line-height: 16px;
      }
    }
  }

  .rc-virtual-list-holder {
    max-height: fit-content !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    display: none;
  }

  &.ranking {
    .rc-virtual-list-holder {
      max-height: 500px !important;
    }
  }
}

.ant-message {
  .ant-message-custom-content {
    display: flex;
    align-items: center;
  }
}

.ant-input-number {
  .ant-input-number-handler-wrap {
    background: transparent;
    span {
      border: none;
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
      color: #2750fb;
    }
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
      color: #2750fb;
    }
  }
}
