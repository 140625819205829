.modal-add-fund {
  @media (max-width: 991px) {
    position: fixed;
    top: auto;
    bottom: 0;
    padding-bottom: 0;
    min-width: 100%;
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 16px 16px 0 0;
    background: #fefefe;

    @media (min-width: 992px) {
      border-radius: 16px;
    }
  }

  .ant-modal-close {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #050505;
      }
    }
  }

  .ant-modal-header {
    border-radius: 16px;
    border-bottom: 0;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-bottom: 12px;
    }

    .ant-modal-title {
      color: #050505;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;

      @media (min-width: 991px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .ant-modal-body {
    padding: 16px 16px 24px;

    @media (min-width: 991px) {
      padding: 32px;
    }
  }
}
