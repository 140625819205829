.collectionCell-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  overflow: hidden;
  &:hover {
    img {
      transition: 0.3s;
      transform: scale(1.1);
    }
  }
  .collectionCell-avatar.ant-avatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: #ffffff;
  }
  .collectionCell-avatar.ant-avatar-square {
    border-radius: 4px;
  }
  .collectionCell-title {
    flex-shrink: 0;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
}
