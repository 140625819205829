.modal-buy-now-nft {
  @media (max-width: 991px) {
    position: fixed;
    top: auto;
    bottom: 0;
    padding-bottom: 0;
    min-width: 100%;
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 16px 16px 0 0;
    background: #fefefe;

    @media (min-width: 992px) {
      border-radius: 16px;
    }
  }

  .ant-modal-header {
    border-radius: 16px;
    border-bottom: 0;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-bottom: 12px;
    }

    .ant-modal-title {
      color: #050505;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;

      @media (min-width: 991px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .ant-modal-body {
    padding: 16px;
    padding-top: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-top: 12px;
    }

    .approve-nft-info {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        margin-bottom: 24px;
      }

      .approve-nft-info-image {
        position: relative;
        width: 32px;
        height: 32px;
        margin-right: 8px;

        @media (min-width: 992px) {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }

        img {
          border-radius: 4px;
          width: 100%;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }

        .approve-nft-info-image-quantity {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          padding: 4px;
          background: #050505;
          border-radius: 50%;

          p {
            position: relative;
            margin-bottom: 0;
            padding-top: 100%;
            min-width: 14px;

            span {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fafafa;
              font-size: 8px;
              line-height: 12px;
              font-style: normal;
              font-weight: 400;

              @media (min-width: 991px) {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }

      .approve-nft-info-name {
        flex: 1;

        p {
          color: #050505;
          font-size: 12px;
          line-height: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 4px;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            color: #666;
            font-weight: 400;
          }
        }
      }

      .approve-nft-info-price {
        text-align: right;

        p {
          color: #050505;
          font-size: 12px;
          line-height: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 4px;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            font-weight: 400;
          }
        }
      }
    }

    .approve-nft-price {
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-bottom: 16px;
      padding: 0 16px;

      @media (min-width: 991px) {
        margin-bottom: 24px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;

        p {
          color: #050505;
          font-family: "Arabic Pro";
          font-size: 12px;
          line-height: 16px;
          font-style: normal;
          font-weight: 400;
          margin: 0;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
          }

          b {
            display: contents;
            font-weight: 700;
          }
        }
      }

      .approve-nft-price-wrap-item {
        border-top: 1px solid #e6e6e6;
        padding: 4px 0;

        @media (min-width: 991px) {
          padding: 8px 0;
        }

        .approve-nft-price-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;

          @media (min-width: 991px) {
            padding: 8px 0;
          }

          > div {
            color: #272727;
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
            font-weight: 400;

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .approve-nft-action {
      button {
        color: #fafafa;
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: 700;
        border-radius: 8px;
        background: #2750fb;
        padding: 12px 16px;
        width: 100%;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
          padding: 16px 24px;
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 12px 16px;
  }
}
