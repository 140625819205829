div.tabs-select {
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-nav {
    height: 100%;
    margin: 0;

    &::before {
      border: unset !important;
    }
  }

  .ant-tabs-nav-wrap {
    color: #45cdf8;
    padding: 2px;
    background: rgba(16, 13, 31, 0.3);
    border: 2px solid #3ddcec;
    border-radius: 30px;
    box-sizing: border-box;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
  }

  // item tabs
  .ant-tabs-tab {
    padding: 4px 16px;
    margin: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    min-width: 80px;
    min-height: 30px;
    // &:hover,
    // &:active {
    //   color: $primary-color;
    // }
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    line-height: 20px;
    white-space: pre-wrap;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }

  // animation
  .ant-tabs-ink-bar {
    background: linear-gradient(90deg, #384cff 0%, #2486f9 0.01%, #3ddcec 100%);
    border-radius: 80px;
    height: 100% !important;
    z-index: -1;
  }
}
