@tailwind base;
@tailwind components;
.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  @apply h-10 w-10 rounded-lg bg-white text-sm text-[#050505] shadow-xl;
}

.swiper .swiper-slide .animate-appear {
  @apply h-0 translate-y-[50vh] opacity-0 transition-all ease-in-out;
}

.swiper .swiper-slide.swiper-slide-active .animate-appear {
  @apply h-fit translate-y-0 opacity-100;
}

.swiper .swiper-slide .animate-appear-trailer {
  @apply w-0 translate-x-[120%] opacity-0 transition-all duration-[2500ms] ease-in-out;
}

.swiper .swiper-slide.swiper-slide-active .animate-appear-trailer {
  @apply w-fit translate-x-0 opacity-100;
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
  @apply rounded-lg bg-white p-3 text-[#050505];
}

.swiper .swiper-button-prev:after,
.swiper .swiper-button-next:after {
  @apply rounded-full text-2xl;
}

.pagination-custom .swiper-pagination-bullet-active {
  @apply bg-black;
}

@tailwind utilities;

@layer components {
  .text-ellipsis-custom {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

img {
  max-width: unset;
  height: auto;
}
