.cart-profile-dropdown-wrapper.open {
  display: block;
}
.cart-profile-dropdown-wrapper.close {
  display: none;
}
.cart-profile-dropdown-wrapper {
  .card-wrapper {
    position: fixed;
    font-size: 16px;
    bottom: 3em;
    top: 1.5em;
    z-index: 999;
    height: calc(((100% - 0px) - 48px));

    overflow: auto;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;

    width: 0px;
    border-radius: 16px;

    border-bottom: 0px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

    @media (max-width: 991px) {
      font-size: 14px;
      border-radius: 0;
    }

    @media (max-width: 768px) {
      // font-size: 10px;
      bottom: 0;
      top: 0;
      // height: calc(((562px - 0px) - 48px));
      height: 100%;
    }

    &.open {
      right: 1.5em;
      width: 28.75em;
      @media (max-width: 768px) {
        width: 100%;
        right: 0;
      }
    }
    &.close {
      right: -100%;
    }
    .ant-dropdown-menu {
      box-shadow: none;
    }
    .ant-card-head-title {
      padding: 0;
    }
    .ant-card-body {
      padding: 1.5em;

      @media (max-width: 991px) {
        padding: 16px;
      }
    }
    .ant-card-extra {
      padding: 0;
    }
    .ant-card-head {
      margin-top: 12px;
      min-height: 3em;
      margin-bottom: 0px;
      padding-left: 1.5em;
      padding-top: 0.75em;
      padding-bottom: 0px;
      padding-right: 1.5em;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1em;
      background: transparent;
      border-bottom: 0px solid #f0f0f0;
      border-radius: 0px 2px 0 0;
    }
    .card-title {
      .ant-card-body {
        display: none;
      }

      .ant-card-bordered {
        border: 0px solid #f0f0f0;
      }

      .ant-card-head {
        border-bottom: 0px solid #f0f0f0;
        .ant-card-head-title {
          color: var(--grayscale-light-black, #050505);
          /* Desk/H2 - Title */
          font-family: "Arabic Pro";
          font-size: 1.5em;
          font-style: normal;
          font-weight: 700;
          line-height: 2em;
          padding: 0;
        }
        .anticon-close {
          cursor: pointer;
          font-size: 1.5em;
          :hover {
            opacity: 0.7;
          }
        }
      }
    }
    .cart-list {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 0.875em;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      .list-item {
        margin-top: 12px;
      }
      .cart-title {
        display: flex;
        justify-content: space-between;
        color: var(--grayscale-light-black, #050505);
        /* Desk/L - Title */
        font-family: "Arabic Pro";
        font-size: 1em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
      }
      .cart-container {
        max-height: calc(625px - 200px);
        overflow-x: auto;
        position: relative;
        transition: opacity 0.3s;
        margin-bottom: 1.5em;
        @media (max-width: 768px) {
          max-height: calc(400px - 162px);
        }
      }
    }
    .cart-footer-wrapper {
      position: relative;
    }

    .back-icon {
      transform: rotate(90deg);
      flex-shrink: 0;

      path {
        fill: #000;
      }
    }

    .certain-category-search-dropdown {
      .ant-select-dropdown {
        max-width: 100%;
      }
    }

    .popup-search-body {
      .input-search {
        flex: 1;
        display: flex;
        padding: 8px;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-radius: 8px;
        border: 1px solid var(--grayscale-light-90, #e6e6e6);

        .ant-input {
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
        }

        &.ant-input-affix-wrapper:focus,
        &.ant-input-affix-wrapper-focused {
          border-color: #e6e6e6;
          box-shadow: none;
          border-right-width: 1px;
          outline: 0;
        }

        &.ant-input:focus,
        &.ant-input-focused {
          border-color: #e6e6e6;
          box-shadow: none;
          border-right-width: 1px;
          outline: 0;
        }
        &.ant-input-affix-wrapper:hover {
          border-color: #e6e6e6;
          border-right-width: 1px;
        }

        .anticon-search {
          display: flex;
          width: 16px;
          height: 16px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #e6e6e6;
          cursor: pointer;

          :hover {
            opacity: 0.7;
          }
        }
        .icon-right {
          display: flex;
          width: 16px;
          height: 16px;
          padding: 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 4px;
          background: var(--grayscale-light-90, #e6e6e6);
        }
      }

      .title-search {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #e6e6e6;

          position: absolute;
          bottom: 0;
          z-index: -1;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          color: #e6e6e6;
          font-weight: bold;
          padding: 8px 16px;
          cursor: pointer;
          transition: all ease 0.3s;
        }
        .active {
          border-bottom: 1px solid #050505;
          color: #050505;
        }
      }

      .search-data-mobile {
        .collectionCell-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0;
          overflow: hidden;

          .collectionCell-avatar.ant-avatar {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            color: #ffffff;
            margin-right: 8px;

            @media (min-width: 991px) {
              margin-right: 16px;
              width: 40px;
              height: 40px;
            }
          }
          .collectionCell-avatar.ant-avatar-square {
            border-radius: 4px;
          }

          .collectionCell-title {
            flex-shrink: 0;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            p,
            span {
              margin-bottom: 0;
              font-size: 10px;
              font-weight: 700;
              line-height: 16px;
            }

            span {
              color: #666;
              font-weight: 400;
            }
          }

          .video-container {
            position: relative;
            width: 32px;
            height: 32px;
            overflow: hidden;
            border-radius: 3px;
            margin-right: 8px;

            iframe {
              box-sizing: border-box;
              height: 56.25vw;
              left: 50%;
              min-height: 100%;
              min-width: 100%;
              transform: translate(-50%, -50%);
              position: absolute;
              top: 50%;
              width: fit-content;
            }
          }
        }

        .footer-search {
          font-size: 12px;
          font-weight: bold;
          color: #666666;

          @media (min-width: 992px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
