@import "./variables.scss";

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.text-success {
  color: $success-color;
}
.d-flex {
  display: flex;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.align-items-center {
  align-items: center;
}
