@import "./variables.scss";
@import "./col.scss";

$listMarginTop: (
  mt-3: 3px,
  mt-5: 5px,
  mt-10: 10px,
  mt-20: 20px,
  mt-30: 30px,
  mt-40: 40px,
  mt-50: 50px,
  mt-60: 60px,
);

$listMarginBottom: (
  mb-3: 3px,
  mb-10: 10px,
  mb-15: 15px,
  mb-20: 20px,
  mb-30: 30px,
  mb-40: 40px,
);

$listMarginLeft: (
  ml-3: 3px,
  ml-5: 5px,
  ml-10: 10px,
  ml-20: 20px,
  ml-30: 30px,
  ml-40: 40px,
);
$listMarginRight: (
  mr-1: 1px,
  mr-2: 2px,
  mr-3: 3px,
  mr-5: 5px,
  mr-7: 7px,
  mr-8: 8px,
  mr-10: 10px,
  mr-15: 15px,
  mr-20: 20px,
  mr-30: 30px,
  mr-40: 40px,
);

$listWidth: (
  w-10: 10px,
  w-20: 20px,
  w-30: 30px,
  w-40: 40px,
  w-10pc: 10%,
  w-20pc: 20%,
  w-30pc: 30%,
  w-100pc: 100%,
  w-10vw: 10vw,
  w-20vw: 20vw,
  w-30vw: 30vw,
);

$listHeight: (
  h-10: 10px,
  h-20: 20px,
  h-30: 30px,
  h-40: 40px,
);

$listPositionText: (
  t-center: center,
  t-right: right,
  t-left: left,
);

$listBorderRadius: (
  border-radius-5: 5px,
  border-radius-10: 10px,
  border-radius-15: 15px,
  border-radius-20: 20px,
);

@include create-class($attribute: "text-align", $list: $listPositionText);
@include create-class($attribute: "margin-top", $list: $listMarginTop);
@include create-class($attribute: "margin-bottom", $list: $listMarginBottom);
@include create-class($attribute: "margin-left", $list: $listMarginLeft);
@include create-class($attribute: "margin-right", $list: $listMarginRight);
@include create-class($attribute: "width", $list: $listWidth);
@include create-class($attribute: "height", $list: $listHeight);
@include create-class($attribute: "border-radius", $list: $listBorderRadius);
