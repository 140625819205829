.btn-connect-wallet {
  width: 100%;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #2750fb;
  height: 42px;

  color: #fafafa;
  /* Desk/L - Title */
  font-size: 16px;
  font-family: "Arabic Pro";
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: #2750fb;
  color: #2750fb;
}
