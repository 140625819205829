.cart-item-wrapper {
  .cart-item {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin: 0.5em;
    .cart-item-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .title-avatar {
        width: 48px;
        height: 48px;
        font-size: 32px;
        @media (max-width: 1024px) {
          font-size: 28px;
          width: 32px;
          height: 32px;
        }
      }
      .title-content {
        display: flex;
        flex-direction: column;
        .title-name {
          color: var(--grayscale-light-black, #050505);
          font-size: 1em;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5em;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .title-description {
          color: var(--grayscale-light-secondary, #666);
          font-size: 0.875em;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5em;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .cart-item-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: end;
      @media (max-width: 1024px) {
        padding-right: 0.5em;
      }
      .content-opv {
        color: var(--grayscale-light-black, #050505);
        /* Desk/L - Title */
        font-family: "Arabic Pro";
        font-size: 1em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
      }
      .content-price {
        color: var(--grayscale-light-secondary, #666);
        /* Desk/M */
        font-family: "Arabic Pro";
        font-size: 0.875em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5em;
      }
    }
  }
}
