.custom-btn-link {
  color: var(--grayscale-light-secondary, #666);
  /* Desk/L */
  font-family: "Arabic Pro";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
}
.custom-btn-primary {
  // @apply bg-[#2750fb] hover:bg-[#20B7F5] active:bg-[#20B7F5] focus:bg-[#20B7F5] border-none rounded-[8px] px-[24px] py-[16px];
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  background-color: #2750fb;

  &:hover,
  &:active,
  &:focus {
    background-color: #2750fb;
    color: #fff;
    opacity: 0.7;
  }
}
.custom-btn-default {
  border: 1px solid #e6e6e6;
  color: #050505;
  padding: 16px 24px;
  border-radius: 8px;
  // @apply rounded-[8px] px-[24px] py-[16px];

  &:hover,
  &:focus {
    color: #050505;
    border: 1px solid #999999;
  }
}
