@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;600;700&family=Roboto:wght@400;700&display=swap");

.footer-container {
  background-color: #102e48 !important;
  position: relative;

  .left-bottom {
    left: 0;
    position: absolute;
    z-index: 99;
    bottom: 0;
  }

  .right-bottom {
    right: 0;
    position: absolute;
    z-index: 99;
    bottom: 0;
  }

  .upper-footer {
    width: 100%;
    height: 60px;
    background-color: #000;
    border-bottom: 2px solid #adb0b2;
  }
}

@media (max-width: 820px) {
  .list-footer {
    flex-wrap: wrap;
    display: flex;

    li {
      width: 50%;
    }
  }
}

footer {
  color: #272727;
  border-top: 1px solid var(--Grey-100, #e2e2e4);
  // margin: 0px -32px;
  padding: 0px 32px;

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent !important;
    position: relative;
    margin-top: 62px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    transition: all 0.5s ease-in-out;
    gap: 1rem;

    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      flex-direction: row;
    }

    .footer-left {
      display: flex;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      margin-bottom: 40px;
      gap: 1em;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    .footer-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex: 1;
      gap: 0.875em;
      flex-wrap: wrap;

      .footer-left-title {
        color: var(--grayscale-light-black, #050505);
        /* Desk/H2 - Title */
        font-size: 24px;
        font-family: "Arabic Pro";
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 24px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
      }

      .footer-left-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .content-description {
          color: var(--grayscale-light-black, #050505);
          /* Desk/L */
          font-size: 16px;
          font-family: "Arabic Pro";
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .input-address {
          background-color: #f3f3f4;
          height: 40px;
          border-radius: 8px;
          border: 1px solid var(--grayscale-light-90, #e6e6e6);

          & > .ant-input {
            background-color: #f3f3f4;
          }

          &.ant-input-affix-wrapper:focus,
          &.ant-input-affix-wrapper-focused {
            border-color: #e6e6e6;
            box-shadow: none;
            border-right-width: 1px;
            outline: 0;
          }

          &.ant-input:focus,
          &.ant-input-focused {
            border-color: #e6e6e6;
            box-shadow: none;
            border-right-width: 1px;
            outline: 0;
          }

          .anticon-arrow-right {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #e6e6e6;
            cursor: pointer;

            :hover {
              opacity: 0.7;
            }
          }
        }
      }

      .margin-left {
        @media (max-width: 768px) {
          margin-left: 16px;
        }
      }
      .footer-right-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media (max-width: 1100px) {
          flex: 138px;
        }
        .content-title {
          color: var(--grayscale-light-black, #050505);
          /* Desk/H2 - Title */
          font-size: 22px;
          font-family: "Arabic Pro";
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          margin-bottom: 24px;

          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
          }
        }
        .content-description {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: var(--grayscale-light-secondary, #666);
          /* Desk/L */
          font-size: 16px;
          font-family: "Arabic Pro";
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 16px;
          }
          a {
            font-size: 14px;
            color: var(--grayscale-light-secondary, #666);
            color: #676472;
          }
          .description-link {
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

.footer-copy-right {
  background: var(--grayscale-light-94, #f0f0f0);
  color: var(--grayscale-light-black, #050505);
  /* Desk/M */
  font-size: 14px;
  font-family: "Arabic Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 476px) {
    padding: 16px 0px 16px 16px;
  }

  @media (min-width: 768px) {
    // margin-left: 80px;
  }

  .live-footer-container {
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding: 16px 32px;

    @media (max-width: 476px) {
      display: unset;
      padding: 0;
    }

    p {
      display: inline-block;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
      // font-family: ""Arabic Pro"", sans-serif;

      span {
        color: #666;
        font-weight: 400;
      }
    }
  }
}
