.nav-links {
  height: 100%;
  margin-top: 40px;

  li {
    position: relative;
    list-style: none;

    &:hover {
      background: rgba(29, 167, 223, 0.1);
    }

    a {
      display: flex;
      align-items: end;
      text-decoration: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
    }

    .iocLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-items: center;
    }
  }

  .icon-nav {
    height: 25px;
    min-width: 40px;
    text-align: center;
    line-height: 25px;
    color: #666666;
    font-size: 20px;
  }
  .icon-down {
    height: 24px;
    min-width: 24px;
    text-align: center;
    line-height: 24px;
    color: #666666;
    font-size: 18px;
  }

  .logo-name {
    font-family: "" Arabic Pro "";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #666666;
  }
}

.menu {
  border-right: none;
  span {
    font-size: 16px;
  }
  .ant-menu-item {
    border-radius: 10px;
    color: #666666;
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      color: #666666;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title,
    .ant-menu-submenu-arrow {
      color: #2750fb !important;
    }
  }
  .ant-menu-item-selected {
    background-color: #1da7df1a;
    color: #2750fb !important;
    &::after {
      display: none;
    }
  }
  .ant-menu-item-selected::after {
    display: none;
  }
  .ant-menu-submenu-arrow {
    color: #666666 !important;
    &::before,
    &::after {
      width: 8px;
    }
  }
  .ant-menu-inline {
    border-radius: 10px;
  }
}
