.header-wrapper .group-categories-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow: scroll;
  overflow-y: hidden;
  max-width: 100%;
  @media (max-width: 768px) {
    gap: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
    width: 0em;
    height: 0em;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .btn-text {
    color: var(--grayscale-light-secondary, #666);
    /* Desk/L */
    font-family: "Arabic Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    height: 48px;
    @media (max-width: 768px) {
      color: var(--grayscale-light-secondary, #666);
      /* Mobile/L-Title */
      font-family: "Arabic Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      height: 32px;
    }
  }
  .btn-text.active {
    border-radius: 8px;
    background: var(--grayscale-light-90, #e6e6e6);
  }
}
