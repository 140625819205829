@font-face {
  font-family: $base-font;
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "DM Sans Bold";
  src: url("../fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SVN Avo";
  src: url("../fonts/SVN_Avo/SVN-Avo.ttf") format("truetype");
}
@font-face {
  font-family: "" Arabic Pro "";
  src: url("../fonts/BeVietnamPro/BeVietnamPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Digital";
  src: url("../fonts/Digital//digital-7.ttf") format("truetype");
}
@font-face {
  font-family: "Arabic Pro";
  src: url("../fonts/ArabicPro/BasisGrotesqueArabicPro-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Pro";
  src: url("../fonts/ArabicPro/BasisGrotesqueArabicPro-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Pro";
  src: url("../fonts/ArabicPro/BasisGrotesqueArabicPro-Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Pro";
  src: url("../fonts/ArabicPro/BasisGrotesqueArabicPro-Black.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-VariableFont_opsz\,wght.ttf")
    format("truetype");
}
