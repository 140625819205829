.modal-place-bid {
  .ant-modal-header {
    border-radius: 16px;
    border-bottom: 0;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-bottom: 12px;
    }

    .ant-modal-title {
      color: #050505;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 700;

      @media (min-width: 991px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .ant-modal-body {
    padding: 16px;
    padding-top: 8px;

    @media (min-width: 991px) {
      padding: 32px;
      padding-top: 12px;
    }

    .approve-nft-info {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        margin-bottom: 24px;
      }

      .approve-nft-info-image {
        position: relative;
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 8px;

        @media (min-width: 991px) {
          width: 64px;
          height: 64px;
          margin-right: 16px;
        }

        img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .approve-nft-info-image-quantity {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          padding: 4px;
          background: #050505;
          border-radius: 50%;

          p {
            position: relative;
            margin-bottom: 0;
            padding-top: 100%;
            min-width: 14px;

            span {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fafafa;
              font-size: 8px;
              line-height: 12px;
              font-style: normal;
              font-weight: 400;

              @media (min-width: 991px) {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }

      .approve-nft-info-name {
        flex: 1;

        p {
          color: #050505;
          font-size: 12px;
          line-height: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 4px;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            color: #666;
            font-weight: 400;
          }
        }
      }

      .approve-nft-info-price {
        text-align: right;

        p {
          color: #050505;
          font-size: 12px;
          line-height: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 4px;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            font-weight: 400;
          }
        }
      }
    }

    .approve-nft-price {
      @media (min-width: 991px) {
        margin-bottom: 24px;
      }

      .approve-nft-price-wrap-item {
        &:not(:last-child) {
          border-radius: 8px;
          border: 1px solid #e6e6e6;
          margin-bottom: 16px;
          padding: 4px 16px;

          @media (min-width: 991px) {
            padding: 8px 16px;
          }

          .approve-nft-price-item {
            padding: 4px 0;

            @media (min-width: 991px) {
              padding: 8px 0;
            }
          }
        }

        .approve-nft-price-item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ant-input-number {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            background: transparent;

            .ant-input-number-handler-wrap {
              display: none;
            }
          }

          > div {
            color: #272727;
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
            font-weight: 400;

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .approve-nft-action {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fafafa;
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: 700;
        border-radius: 8px;
        background: #2750fb;
        padding: 12px 16px;
        width: 100%;
        height: auto;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
          padding: 16px 24px;
        }

        &.disabled {
          border-color: #cccccc;
          background-color: #cccccc;
          color: #fff;
        }

        .ant-btn-loading-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 12px 16px;
  }
}
